import Flash from './Flash';

class Board {
   history: Flash[];
   score: number; 
   onFlash: (flash: Flash) => void; 
   onScoreChange: (score: number) =>  void;
   soundTested: boolean = false ; 
   positionTested: boolean = false; 

   constructor(onScoreChange: (score: number) => void) 
   {
       this.onScoreChange = onScoreChange; 
       this.history = [];
       this.score = 0; 
   }

   start(onFlash: (flash: Flash) => void) {
       this.onFlash = onFlash; 
       this.timerToken = window.setInterval(() => 
       onFlash(this.next()), 2500);
   }

   stop() {
       clearInterval(this.timerToken);
       delete this.onFlash; 
       delete this.timerToken;        
   }

   samePosition() {
       if (this.positionTested) {
           return;            
       }
       if (this.history.length > 1 && (this.history[0].position[0] === this.history[1].position[0])
            && (this.history[0].position[1] === this.history[1].position[1])) {
           this.updateScore(100);
       } else {
           this.updateScore(-50);
       } 
       this.positionTested = true; 
   }

   sameSound() {
       if (this.soundTested) {
           return; 
       }
       if (this.history.length > 1 && (this.history[0].sound === this.history[1].sound)) {
           this.updateScore(100);
       } else {
           this.updateScore(-50);
       }
       this.soundTested = true; 
   }

   next() {
       let p = 0.15;

       console.log('next flash');
       let i = this.randomInRange(0,2);
       let j = this.randomInRange(0,2);
       let s = this.randomInRange(1,9);

       if (this.history.length > 0 && Math.random() < p) {
           i = this.history[0].position[0];
           j = this.history[0].position[1];
       }
       if (this.history.length > 0 && Math.random() < p) {
           s = this.history[0].sound;           
       }
       let newFlash = new Flash([i, j], s);
       this.history.unshift(newFlash);
       this.soundTested = false; 
       this.positionTested = false; 
       return newFlash;       
   }

   updateScore(delta: number) {
       this.score += delta; 
       this.onScoreChange(this.score);
       console.log("New score", this.score);
   } 

   randomInRange(min: number, max: number) {
       return Math.floor(Math.random() * (max-min + 1) + min);
   }
}

export default Board;