import React, { Component } from 'react';
import Grid from './Grid';
import Board from './Board';
import Flash from './Flash';
import './Game.css';
import axios from 'axios';
//import store from '../../store/index';
import { Table } from 'react-bootstrap';

export interface IState {
    running: boolean;
    board: Board;
    currentFlash?: Flash;  //Optional parameter
    score: number;
}

export interface IProps {

}

class Game extends Component<IProps, IState> {
    constructor(props: any) {
        super(props);


        this.onScoreChange = this.onScoreChange.bind(this);
        this.state = {
            running: false,
            board: new Board(this.onScoreChange),
            currentFlash: undefined,
            score: 0,
            userList: [],
            ws: WebSocket
        }

        this.onFlash = this.onFlash.bind(this);
        this.trySound = this.trySound.bind(this);
        this.tryPosition = this.tryPosition.bind(this);
        this.onPlay = this.onPlay.bind(this);
        this.onPause = this.onPause.bind(this);
    }

    wsConnectionUrl = 'wss://guarded-peak-91418.herokuapp.com/';

    //ws = new WebSocket('ws://localhost:4000');

    webSocketHook() {
        let ws = new WebSocket('wss://guarded-peak-91418.herokuapp.com/');
        ws.onopen = () => {
            // on connecting, do nothing but log it to the console
            console.log('connected')
        };
        ws.onmessage = evt => {
            console.log("MESSAGE FROM SERVER: ", evt.data);
            // listen to data sent from the websocket server
            if (evt.data === 'UPDATE HIGHSCORES') {
                this.getUsersForHighscoreBoard();
            };
        };
        ws.onclose = () => {
            console.log('disconnected')
            // automatically try to reconnect on connection loss
            this.webSocketHook();
        };
        this.setState({ws: ws});
    }

    componentDidMount() {
        //console.log("Store info: " + store);
        this.webSocketHook();
        this.getUsersForHighscoreBoard();
    }

    getUsersForHighscoreBoard() {
        axios.get('https://guarded-peak-91418.herokuapp.com/api/users/allUsers', {
        }).then(res => {
            var tempList = [];
            res.data.forEach(element => {
                tempList.push(element);
            });
            this.setState({userList: tempList});
        }).catch(error => {
            console.log("Error:" + error.message);
            this.setState(state => {
                state.userList.push("Error fetching score screen...");
            });
            return {
                userList: this.state.userList
            };
        });
    }

    onFlash(newFlash: Flash) {
        this.setState({ currentFlash: newFlash });
        this.speak(newFlash.sound.toString());
    }

    tryPosition() {
        this.state.board.samePosition();
    }

    trySound() {
        this.state.board.sameSound();
    }

    render() {
        let props = {};
        if (this.state.currentFlash) {
            props.highlight = this.state.currentFlash.position;
        }

        var sortedList = this.state.userList.sort(function (a, b) {
            return b.highscore - a.highscore;

        });

        const items = []

        for (const [index, value] of sortedList.entries()) {
            if (value === "Error") {
                items.push(
                    <tr key={index}>
                        <td>{value}</td>
                    </tr>)
            }
            items.push(
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{value['emailAddress']}</td>
                    <td>{value['highscore']}</td>
                </tr>
            )
        }

        return (
            <div className="Game container">
                <div className="row align-items-start">
                    <div className="col-sm-8">
                        <Grid rows="3" columns="3" {...props} ></Grid>
                        <div className="PlayButtons text-center">
                            <button type="button" onClick={this.tryPosition} className={!this.state.running ? 'hidden' : 'btn btn-primary btn-lg'}>A: Position Match</button>
                            <button type="button" onClick={this.trySound} className={!this.state.running ? 'hidden' : 'btn btn-primary btn-lg'}>L: Audio Match</button>
                            <button color="primary" className={this.state.running ? 'hidden' : 'btn btn-primary btn-lg'} onClick={this.onPlay}>Play</button>
                            <button color="primary" className={!this.state.running ? 'hidden' : 'btn btn-secondary btn-lg'} onClick={this.onPause}>Pause</button>
                        </div>
                        <p className="score text-center">Score: {this.state.score}</p>
                    </div>
                    <div className="col-sm-4">
                        <div>
                            <Table className="scoreTable" responsive onSubmit={this.componentDidMount}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>User</th>
                                        <th>Top-score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

                
                
            </div>
        );
    }

    speak(text: string) {
        if ('speechSynthesis' in window) {
            const utterance = new SpeechSynthesisUtterance();
            utterance.text = text;
            utterance.voice = speechSynthesis.getVoices().filter((voice) => {
                return voice.name === "Allison";
            })[0];
            window.speechSynthesis.speak(utterance);
        }
    }

    onPlay() {
        this.setState({ running: true });
        this.state.board.start(this.onFlash);
    }

    onPause() {
        this.setState({ running: false });
        this.state.board.stop();
    }

    onScoreChange(nextScore: number) {
        this.setState({ score: nextScore });

        var currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
        var jwtToken = localStorage.getItem('JWTToken');
        axios.defaults.headers.common['Authorization'] = localStorage.getItem('JWTToken');

        if (currentUser != null) {
            if (currentUser.highscore < nextScore) {
                axios.post('https://guarded-peak-91418.herokuapp.com/api/users/updateHighScore', {
                    highscore: nextScore
                }, {
                    headers: { 'Authorization': `Bearer ${jwtToken}` }
                }).then(res => {
                    currentUser.highscore = nextScore;
                    localStorage.setItem('CurrentUser', JSON.stringify(currentUser))
                    this.state.ws.send(JSON.stringify(currentUser));
                }).catch(err => {
                    if (err.response.status === 400) console.log("BAD REQUEST", err.response.status)
                    else if (err.response.status === 401) console.log("UNAUTHORIZED", err.response.status)
                });
            }
        }        
    }
}
export default Game;
