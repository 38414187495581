import React, { Component } from 'react';
import './App.css';
import Game from './components/gameComponent/Game';
import Register from './components/registerComponent/Register';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import Login from './components/loginComponent/Login';

class App extends Component { 
  
    render() {
        const navigationFields = [];
        var currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
        navigationFields.push( <li className="nav-item" key="0" ><Link to={'/'} className="nav-link">Game</Link></li> );
        if (currentUser != null) {
            navigationFields.push(<li className="nav-item" key="3" ><Link to={'/'} onClick={this.logout} className="nav-link">Logout ({currentUser.emailAddress})</Link></li>);        
        } else {
            navigationFields.push(<li className="nav-item" key="1" ><Link to={'/register'} className="nav-link">Register</Link></li>);
            navigationFields.push(<li className="nav-item" key="2"><Link to={'/login'} className="nav-link">Login</Link></li>);
        }
        return (
            <Router>
                <div className="App">
                    <ul className="nav nav-tabs">
                        {navigationFields}
                    </ul>
                    <header className="App-header">
                        <span className="glow">Dual-n-Back</span>  
                    </header>
                    <Switch>
                        <Route exact path='/' component={Game} />
                        <Route path='/login' render={(props) => <Login {...props} callback={this.refreshNavigationBar} />}/> 
                        <Route path='/register' component={Register} />
                    </Switch>
                </div>
            </Router>
        );
    }

    logout = () => {
        localStorage.clear("JWTToken");
        localStorage.clear("CurrentUser");
        this.forceUpdate();
    }

    refreshNavigationBar() {
        window.location.reload();
    }
}

export default App;