import React from 'react';
import { Form } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import './Login.css';
import axios from 'axios';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.refreshNavigationBar = this.refreshNavigationBar.bind(this);
        this.state = {
            username: '',
            password: ''
        };
    }

    refreshNavigationBar() {
        this.props.callback();
    }

    render() {
        return (
            <Form autoComplete="off" className="loginForm">
                <div>
                    <TextField
                        autoComplete='new-password'
                        variant="outlined"
                        placeholder="Enter email"
                        label="Required*"
                        id="standard-required"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) =>
                            this.setState({ username: event.target.value })
                        }
                    />
                    <br />
                    <TextField
                        autoComplete='new-password'
                        variant="outlined"
                        type="password"
                        placeholder="Enter password"
                        onChange={(event) =>
                            this.setState({ password: event.target.value })
                        }
                    />
                    <br />
                    <Button variant="contained" label="Submit" style={style} onClick={(event) => this.handleClick(event)}>Login</Button>
                </div>
            </Form>
        );
    }

    renderRedirect = () => {
        this.props.history.push("/");
    }

    handleClick(event) {
        console.log(this.state.username);
        console.log(this.state.password);
        axios.post('https://guarded-peak-91418.herokuapp.com/api/users/login', {
            emailAddress: this.state.username,
            password: this.state.password,
        }).then(res => {
            localStorage.setItem('JWTToken', res.data.token)
            localStorage.setItem('CurrentUser', JSON.stringify(res.data.user))
            this.renderRedirect();
            this.props.callback();

        }).catch(err => {
            console.log(err);
            if(err.response.status === 400) console.log("BAD REQUEST", err.response.status)
            else if(err.response.status === 401) console.log("UNAUTHORIZED", err.response.status)
        });
    }
}
const style = {
    margin: 15,
};
export default Login;