import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Register.css';
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            repeatPassword: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        if (this.state.password !== this.state.repeatPassword) {
            alert("Passwords do not match!");
            this.setState({ password: '' });
            this.setState({ repeatPassword: '' });

            event.preventDefault();
        } else {
            axios.post('https://guarded-peak-91418.herokuapp.com/api/users/createUser', {
                emailAddress: this.state.email,
                password: this.state.password,
            }).then(res => {
                this.renderRedirect();

            }).catch(err => {
                if (err.response.status === 400) console.log("BAD REQUEST", err.response.status)
                else if (err.response.status === 401) console.log("UNAUTHORIZED", err.response.status)
            });
            event.preventDefault();           
        }
    }

    renderRedirect = () => {
        this.props.history.push("/login");
    }

    handleChange(event) {
         this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        return (
            <div className="container">
                <h1>Register your account</h1>

                <div className="row">
                    <div className="col-6">
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formGroupEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control name="email" value={this.state.email} type="email" placeholder="Enter email" onChange={this.handleChange} />
                            </Form.Group>
                            <Form.Group controlId="formGroupPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control name="password" value={this.state.password} type="password" placeholder="Password" onChange={this.handleChange} />
                            </Form.Group>
                            <Form.Group controlId="formGroupPassword">
                                <Form.Label>Repeat password</Form.Label>
                                <Form.Control name="repeatPassword" value={this.state.repeatPassword} type="password" placeholder="Repeat password" onChange={this.handleChange} />
                            </Form.Group>
                            <Button variant="primary" type="submit" >
                                Register account
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Register;