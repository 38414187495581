// store.js
import { combineReducers } from 'redux';

const listReducer = (state = [], action) => {
    switch (action.type) {
        case 'RUNNING':
            return [...state, { ...action.payload }];
        case 'NOT_RUNNING':
            return state.filter(item => item.id !== action.payload.id);
        default:
            return state;
    }
};
const store = combineReducers({
    list: listReducer
});
export default store;