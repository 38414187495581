class Flash{
    position: [Number, number];
    sound: Number; 
    constructor(
        position: [number, number],
        sound: number
    ) {
        this.position = position;
        this.sound = sound; 
    }
}

export default Flash; 